@import "../../../styles/Theme";

.spinner {

  &__overlay {
    height: 100%;
    position: absolute;
    width: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 2;
    cursor: not-allowed;
  }
}
