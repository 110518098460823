@import "../../../../styles/Theme";

.addUser {

  &__description {
    font-weight:400;
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    align-self: center;
    margin-left: 5px;
    width: 16px;
    height: 16px;
  }

  &__icon {
    @include theme-aware('fill', 'color-main');
  }

  &__titleSection {
    margin-top: 30px;
    font-weight:700;
    font-size: 20px;
    @include theme-aware('color', 'color-main');
  }

  &__clickableText {
    display: flex;
    width: 100%;
    @include theme-aware('color', 'color-main');
    font-size: 16px;
    font-weight:700;
    cursor: pointer;
    margin-bottom: 20px;
    text-decoration: underline;
  }

  &__label{
    cursor: pointer;
    margin: 0;
    display:flex;
    flex-direction: row;
  }

  &__radioButtonContainer{
    margin-bottom:20px;
  }

  &__error{
    color: $COLOR_ERROR;
    text-align: right;
  }
  &__upload{
    display: flex;
    margin-bottom: 30px;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__quaterInput{
    min-width: 18%;
    margin-top: 30px;
    &--noMargin{
      margin-left: 0 !important;
    }
    &--noMarginRight{
      margin-right: 0 !important;
    }
  }
}
