@import "../../../styles/Theme";

.customScrollableTable {
  width: 100%;
  margin-top: 20px;

  &__container {
    width: 100%;
    border: 1px solid $COLOR_DARK_GREY;
    border-radius: 7px;
    font-family: Lato, sans-serif;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 40px;
    max-height: 200px;
    overflow: auto;
  }
  &__description{
    font-size: 14px;

  }

  &__title{
    @include theme-aware('color', 'color-main');
    font-weight:700;
    display: flex;
    flex: 1;
  }

  &__emptyMessage{
    padding-top:10px;
    padding-bottom:10px;
    text-align: center;
  }

  &__emptyRow{
    flex:1;
    margin-right:15px;
  }

  &__rowContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }

  &__rowTitle{
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px $COLOR_GREY solid;
    padding: 12px 0 12px 40px;
  }

  &__row {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px $COLOR_GREY solid;
    padding: 10px 0;
    &:hover{
      @include theme-aware('background-color', 'color-main-light-hover');
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__checkboxCell {
    margin-right: 20px;
    margin-left:20px;
  }

  &__cell {

    display: flex;
    flex: 1;
    font-size: 14px;
  }
}
