.filter {

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__filterButton {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    padding-top: 5px;
    max-width: 200px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title {
    font-weight:700;
    font-size: 16px;
  }

}
