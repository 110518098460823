@import "../../../styles/Theme";
@import "../../../styles/Variables";

.blockUser {
  &__tableRow {
    display: flex;
    padding: 10px 40px;
  }
}
.action_dot {
  @include theme-aware("color", "color-main");
}
