@import '../../../styles/Variables';
@import "../../../styles/Theme";

.button {
  height: 40px;
  border-radius: 5px;
  padding: 0 20px;
  font-weight:700;
  text-align: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  color: white;
  @include theme-aware('background', 'color-background');

  &--blocked {
    @include theme-aware('background', $COLOR_TEXT_DISABLED);
    cursor: not-allowed !important;
  }

  &--blue {
    border: 2px solid $COLOR_BLUE;
    color: $COLOR_BLUE;
    @include theme-aware('background', transparent);
    width: 350px;
    font-weight:700;
    font-size: 16px;
  }

  &--primarySmallerFont {
    font-size: 14px;
  }

  &--greenButton {
    color: white;
    @include theme-aware('background', #79C88A);
  }

  &--redButton {
    color: white;
    @include theme-aware('background', $COLOR_ERROR);
  }

  &__img {
    height: 19px;
    width: 18px;
    margin-left: 10px;

    &--previousArrow {
      margin-left: 0;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__linkDownload {
    color: white;

    &:hover {
      color: white;
    }
  }

  &__content {
    flex: 1;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;

    &--displayFlex {
      display: flex;
      @include mobile {
        justify-content: center;
        align-items: center;
      }
    }
  }

  &--inverted {
    border: 2px solid;
    @include theme-aware('color', 'color-main');
    @include theme-aware('border-color', 'color-main');
    @include theme-aware('background', white);
    font-size: 16px;
  }

  &--disabled {
    @include theme-aware('background', $COLOR_TEXT_DISABLED);
    cursor: not-allowed !important;
  }

  &--disabledWhite {
    background: white !important;
    color: #A5A5A5;
    cursor: not-allowed !important;
  }

  &--white {
    background: white !important;
    color: #A5A5A5;
  }

  &--black {
    border: 2px solid;
    @include theme-aware('color', $COLOR_DARK_GREY);
    @include theme-aware('background', transparent);
    @include theme-aware('border-color', $COLOR_DARK_GREY);
  }

  &--bordeaux {
    border: 2px solid;
    @include theme-aware('color', $COLOR_TEXT_BORDEAUX);
    @include theme-aware('background', $COLOR_BACKGROUND_WHITE);
    @include theme-aware('border-color', $COLOR_DARK_GREY);
  }

  &--authButton {
    border: 0;
    font-weight:700;
    font-size: 16px;
    color: white;
    width: 350px;
    @include theme-aware('background', $LINEAR_GRADIENT_TRIPLE);
  }

  &--filter {
    border: 1px solid;
    font-size: 14px;
    height: 30px;
    @include theme-aware('background', white);
    @include theme-aware('border-color', $COLOR_DARK_GREY);
    @include theme-aware('color', $COLOR_DARK_GREY);

    &--height40 {
      height: 40px;
    }

    &--clicked {
      font-size: 14px;
      height: 30px;
      @include theme-aware('background', 'color-background');
      @include theme-aware('color', $COLOR_BACKGROUND_WHITE);

      &--height40 {
        height: 40px;
      }
    }
  }

  &--close {
    background-color: transparent;
  }


  &--noBorder {
    border: none;
  }
}



