@import "../../../styles/Theme";
@import "../../../styles/Variables";

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.customDropdown {

  &__toggle {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 85px;
  }

  &__list {
    margin: 0
  }

  &__filter {
    border: none;
    border-bottom: 1px solid $COLOR_TEXT_GREY;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 15px;
    justify-content: center;
    height: 40px;
    display: block;
  }


  &__dropdown {
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    font-weight: 600;
    border: 1px solid $COLOR_DARK_GREY;
    background: white;
    border-radius: 7px;
    &.show{
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    padding-left: 20px;
    height: 42px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__linkContainer {
    justify-content: flex-start;
    flex: 1;
    max-width: 100%;
    min-width: 0;
    @include ellipsis;
    margin-right: 25px;
  }

  &__link {
    color: $COLOR_DARK_GREY;
    text-decoration: none;

    &:active {
      color: $COLOR_DARK_GREY;
      text-decoration: none;
    }

    &:visited {
      color: $COLOR_DARK_GREY;
      text-decoration: none;
    }
  }

  &__imgContainer {
    position: relative;
    right: 20px;
  }

  &__modify {
    font-weight: 600;
    position: absolute;
    @include theme-aware('color', "color-main");
    float: right;
    font-size: 18px;
    top: 8px;
    right: 20px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
  }

  &__img {
    display: flex;
    justify-content: flex-end;
    height: 15px;
    width: 15px;
  }

  &__titleContainer {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    align-items: center;

    &--marginTop20 {
      margin-top: 20px;
    }
  }

  &__description {

    font-size: 14px;
    color: $COLOR_DARK_GREY;
  }

  &__title {
    color: $COLOR_DARK_GREY;
    font-size: 16px;
    font-weight: 700;
    flex-shrink: 0;

    &--error {
      color: $COLOR_PURPLE_LIGHT;
    }
  }

  &__customMenu.show {
    overflow: auto;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 200px;
    flex-direction: column;
    border-left: 1px solid $COLOR_DARK_GREY;
    border-bottom: 1px solid $COLOR_DARK_GREY;
    border-right: 1px solid $COLOR_DARK_GREY;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background: white;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;

  }

  &__item {
    font-weight: 600;
    font-size: 16px;
    height: 42px;
    cursor: pointer;
    background-color: white;
    white-space: inherit !important;

    &:hover {
      @include theme-aware('background', 'color-background');
    }
  }

  &__noItem {
    font-weight: 600;
    font-size: 16px;
    background-color: white;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
    text-align: center;
  }

  &__placeholder {
    color: $COLOR_TEXT_GREY;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
  }
}

.dropdown-item {
  display: flex !important;
  align-items: center;
  padding-left: 15px;
}

.dropdown-menu {
  min-width: 250px;
  padding: 0 !important;
}
