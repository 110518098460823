@import '../../../styles/Variables';
@import "../../../styles/Theme";

.personalInfo {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__li {
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    float: left;
    padding: 0 25px;
    text-decoration: none;
    color: $COLOR_DARK_GREY;

    &:hover {
      @include theme-aware('color', 'color-main-light');
    }

    &:last-child {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 15px;
    @include theme-aware('background', 'color-background');
    color: white;
    padding: 3px 5px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 7px;
    div.show & {
      background-color: transparent;
    }
    &:hover {
      background: none;
      @include theme-aware('background-color', 'color-main-light-hover');
    }
    @include mobile {
      margin-right: 10px;
    }
    .show &, .show &:hover {
      color: white;
    }
  }

  &__name {
    font-weight:600;
    //font-size: 16px;

    .show &, .show &:hover {
      color: white;
    }

    @include mobile {
      font-size: 14px;
    }
  }

  &__rightName {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


  &__rightTitle {

  }

  &__type {
    margin-left: 25px;
    font-weight: 600;
    @include mobile {
      font-size: 12px;
    }
  }

  &__avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    @include mobile {
      width: 35px;
      height: 35px;
    }
  }

  &__subMenu {
    color: $COLOR_DARK_GREY;
    white-space: nowrap;
    z-index: 2;
    padding-right: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      color: white;
    }

    &--active {
      color: white;
      @include theme-aware('background', 'color-background');
    }

    &.active {
      color: white;
    }

    @include nav-collapsed {
      display: flex;
      justify-content: center;
    }

  }
}

.customProfilePic {
  background-color: #f8f9fa !important;
  height: 60px;
  z-index: 100;

  &__li {
    display: inline-flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    float: left;
    padding: 0 25px;
    text-decoration: none;
    color: $COLOR_DARK_GREY;
    &:hover {
      @include theme-aware('color', 'color-main-light');
    }

    &:last-child {
      margin: 0;
      padding: 0;
    }
  }

  &__personalInfo {
    margin-left: auto;
    @include nav-collapsed {
      margin: 15px;
    }
  }

  &__img {
    padding: 2px !important;
    border-radius: 5px;
    &:hover {
      @include theme-aware('background-color', 'color-main-light-hover');
    }
  }
}

#basic-nav-dropdown {
  height: 60px;
}

.dropdown-menu {
  border: 0;
}

#nav_dropdown__dropdownItem {
  background-color: $COLOR_BACKGROUND_WHITE;
  color: $COLOR_DARK_GREY;

  &:hover {
    @include theme-aware('background', 'color-background');
    color: $COLOR_BACKGROUND_WHITE;
  }
}

a.dropdown-toggle {
  &::after {
    display: none;
  }
}
