@import "../../../styles/Theme";
@import "../../../styles/Variables";

.customUploadFile {
  display: inline-block;
  position: relative;
  width: 230px;
  @include tablet {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__buttonDownload {
    z-index: 1;
    opacity: 0.7;
    & + & {
      margin-top: 5px;
    }
  }

  &__uploadBox {
    height: 150px;

    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $COLOR_TEXT_GREY 1px solid;

    &--colored {
      border: none;
      @include theme-aware('background', 'color-background');
    }

    flex-direction: column;
  }

  &__uploadBoxTextBox {
    background-color: $COLOR_BACKGROUND_WHITE;
    border-radius: 5px;
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &__uploadBoxText {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 700;
    color: $COLOR_DARK_GREY;
    cursor: pointer;

    &--colored {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      border: 1px solid;
      border-radius: 5px;
      width: 100%;
      @include theme-aware('color', 'color-main');
      @include theme-aware('border-color', 'color-main');
    }
  }

  &__uploadImgBoxText {
    opacity: 0.4;
    flex: 1;
    margin-top: 5px;
    object-fit: contain;
    height: 100%;
    //width: 100%;
  }
}
