/* MEDIA QUERY BREAKPOINTS */
$TABLET_DIMENSION: 1024px;
$MOBILE_DIMENSION: 768px;
$CONTAINER_MAX_WIDTH: 1300px;
$NAVBAR_BREAKPOINT: 1200px;

//da 760 in giù
@mixin mobile {
  @media  (max-width: #{$MOBILE_DIMENSION}) {
    @content
  }
}
//da 1024 in giu
@mixin tablet {
  @media  (max-width: #{$TABLET_DIMENSION}) {
    @content
  }
}

//da 1025 in poi
@mixin desktop {
  @media screen and (min-width: $TABLET_DIMENSION+1) {
    @content
  }
}

@mixin container-max {
  @media  (max-width: $CONTAINER_MAX_WIDTH) {
    @content
  }
}

//da 1200 in giu
@mixin nav-collapsed {
  @media  (max-width: $NAVBAR_BREAKPOINT) {
    @content
  }
}
@mixin nav-expanded {
  @media  (min-width: $NAVBAR_BREAKPOINT+1) {
    @content
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}