@import '../../../styles/Variables';
@import '../../../styles/Theme';
.onBoardingCard {

  &__topBar {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0;
    height: 150px;
  }

  &__orangeStripe {
    height: 150px;
    position: absolute;
    width: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    @include theme-aware('background', "color-background");
  }

  &__topBarContainer {
    color: white;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &__navBarAndProgressBarContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &__logo {
    height: 60px;
    font-size: 36px;
    font-family: Heavitas, sans-serif;
    margin-top: 13px;
    margin-right: 30px;
  }

  &__subtitle {
    font-weight:400;
    color: #FFFFFF;
    font-size: 20px;
    align-self: flex-end;
    margin-bottom: 13px;
  }

  &__profile {
   margin-left: auto;
    color: #FFFFFF !important;
  }

  &__navName {
   display: flex;
  }

  &__logoAndSubtitle {
    color: white;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }
}

.pageContainer {
  display: flex;
  background-color: $COLOR_BACKGROUND;
  height: 100vh;
  flex-direction: column;
}
