@import "../../styles/Variables";
@import "../../styles/Theme";

$NAV_BAR_HEIGHT: 60px;

.containerNav {
  background-color: white !important;
  //height: $NAV_BAR_HEIGHT;
  z-index: 100;

  &__containerTriangleAndItem {
    display: flex;
    margin-top: 2px;
    margin-left: 10px;
  }

  &__modalRoles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
    @include mobile {
      flex-direction: column;
    }
  }

  &__modalRoles > * {
    flex: 1;
  }

  @include desktop {
    &__modalRoles > * {
      flex: 0 0 33.3333% !important;
    }
  }

  &__boxOnlyDesktop {
    display: none;
    @include nav-expanded {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &__boxOnlyTablet {
    display: none;
    @include nav-collapsed {
      flex: 1;
      padding-right: 45px;
      display: flex;
      justify-content: flex-end;
    }
    @include mobile {
      padding-right: 39px;
    }
  }

  &__containerTriangle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

    &--clicked {
      color: white;
    }
  }

  &__triangle {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $COLOR_DARK_GREY;

    &--clicked {
      border-top: 6px solid white;
    }
  }

  .pageContainer &__navbar {
    padding: 0;
    z-index: 1;
  }

  &__navbar {
    flex-wrap: wrap !important;
  }

  &__collapse {
    height: $NAV_BAR_HEIGHT;
  }

  @include nav-collapsed {
    &__collapse {
      background-color: white !important;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      height: auto;
    }
  }

  &__nav {
    font-weight: 600;
    flex: 1 1;
    width: 100%;
    height: 100%;
  }

  &__nav {
    height: 100%;
  }

  &__ul {
    display: flex;
    flex: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
  }

  &__subMenu {
    position: relative;
    text-align: center;
    color: $COLOR_DARK_GREY;
    white-space: nowrap;
    z-index: 2;

    &:hover {
      color: white;
    }

    &--active {
      color: white;
      @include theme-aware("background", "color-background");
    }

    &.active {
      color: white;
    }

    @include nav-collapsed {
      display: flex;
      justify-content: center;
    }
  }

  &__li + &__li {
    padding-left: 18px;
    padding-right: 18px;
  }

  &__li {
    display: inline-flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    float: left;
    padding: 0 15px;
    text-decoration: none;
    color: $COLOR_DARK_GREY;
    position: relative;

    &:last-child {
      margin: 0;
      padding: 0;
    }

    &--dropdownItem {
      padding: 0;
    }

    @include nav-collapsed {
      align-items: center;
      justify-content: center;
      height: 40px;
    }

    &:hover {
      @include theme-aware("color", "color-main-light");
    }

    $self: &;

    &#{ $self }--activeItem {
      @include theme-aware("background", "color-background");
      color: white;

      &:hover {
        @include theme-aware("background", "color-background");
        color: white;
        text-decoration: none;
      }
    }
  }

  &__subMenuBubble {
    height: 14px;
    width: 14px;
    line-height: 14px;
    border-radius: 14px;
    position: absolute;
    top: 10px;
    right: 6px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    color: white;
    @include theme-aware("background", "color-background");

    &--inverted {
      background: $COLOR_BACKGROUND !important;
      @include theme-aware("color", "color-main");
    }
  }

  .pageContainer &__logo {
    display: inline-flex;
    text-decoration: none;
    color: $COLOR_DARK_GREY;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  }
}

.pageContainer {
  .navbar-toggler {
    border: none;
    position: absolute;
    right: 10px;
    top: 22px;
    padding-top: 5px;
    @include mobile {
      right: 0;
    }
  }

  .navbar-toggler {
    margin-right: -10px;

    .burger {
      display: block;
      width: 28px;
      height: 30px;
      @include mobile {
        height: 16px;
        width: 20px;
      }
    }

    .burger span:after,
    .burger span:before {
      content: "";
      position: absolute;
      left: 0;
      top: -9px;
    }

    .burger span:after {
      top: 9px;
    }

    .burger span {
      position: relative;
      display: block;
    }

    .burger span,
    .burger span:after,
    .burger span:before {
      width: 100%;
      height: 5px;
      background-color: #888;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
    }
  }

  /* on activation */
  button:not(.collapsed) {
    .burger span {
      background-color: transparent;
    }

    .burger span:before {
      transform: rotate(45deg) translate(5px, 5px);
    }

    .burger span:after {
      transform: rotate(-45deg) translate(7px, -8px);
    }
  }

  .nav-item {
    height: 100%;
    color: white !important;

    &.show,
    &--active {
      @include theme-aware("background", "color-background");
      color: white !important;

      @include nav-collapsed {
        //width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    border: none;
    border-radius: 0;
    padding: 0 15px;
    @include nav-collapsed {
      background-clip: border-box;
      width: 100%;
    }
  }

  .dropdown-item {
    font-weight: 600;
    padding-bottom: 15px;
    padding-top: 15px;
    cursor: pointer;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: white;
      @include theme-aware("background", "color-background");
    }

    &.active {
      @include theme-aware("background", "color-background");
      color: white;
    }

    height: 40px;
    font-size: 16px;

    &:hover {
      color: white;
      background-color: transparent;
    }
  }
}
