@import '../../../styles/Variables';
@import "../../../styles/Theme";

.chooseUserDocumentsType {
  display: inline-flex;
  flex-direction: row;
  width:100%;

  @include tablet {
    display: flex;
    flex-direction: column;
  }

  &__cardContainer {
    margin-right: 30px;

    @include tablet {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__buttonPreviousContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 20px;

    @include tablet {
      position: relative;
      display: flex;
      flex: 1;
      margin-left: 20px;
      bottom: 0;
      right: 20px;
      margin-top: 40px;
    }
  }


}