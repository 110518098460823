@import "../../../../styles/Variables";
@import "../../../../styles/Theme";


.definitionRole {



  &__clipIcon {
    width: 17.86px;
    height: 19px;
    margin-left: 10px;
  }

}

