@import '../../styles/Variables';
@import '../../styles/Theme';

.summaryData {
  &__container{
    margin-bottom:40px;
    overflow:auto;
  }

  &__title {
    font-size: 20px;
    font-weight:700;
    @include theme-aware("color", "color-main");
    margin-bottom: 10px;
    margin-top: 20px;

  }

  &__subTitle {
    font-size: 22px;
    font-weight:700;
    @include theme-aware("color", "color-main");
    margin-bottom: 20px;
    margin-top: 30px;
  }

  &__subSection{
    padding-left: 2rem;
    margin-left: 2rem;
    border-left-width: 1px;
    border-left-style: solid;
    @include theme-aware("border-color", "color-main");
  }

  &__cellContainer {
    display: flex;
    flex-wrap: wrap;

  }

  &__cellTitle {
    color: $COLOR_DARK_GREY;

    font-size: 14px;
    margin-top: 5px;
    white-space: nowrap;
  }

  &__cell {
    flex: 0 1;
    padding-right: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  &__cell + &__cell, &__cell:first-child {
    border-right: lightgray 1px solid;
    padding-left: 2em;
  }

  &__cell:last-child {
    border-right: none;
  }

  &__value {
    color: $COLOR_DARK_GREY;
    font-weight:700;
    font-size: 16px;
    white-space: nowrap;
  }
}


