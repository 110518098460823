@import "src/styles/Variables";
@import "src/styles/Theme";

.definitionRole {

  &__descriptionContainer {
    margin-top: 20px;
    padding-bottom: 10px;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    margin-right: 10px;

    &--clickable {
      cursor: pointer;
      font-weight: 700;
      @include theme-aware('color', 'color-main');
    }
  }
  &__removeDocument {
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: 700;
    @include theme-aware('color', 'color-main');

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__pdfName {
    color: $COLOR_DARK_GREY;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    word-break: break-all;
    text-align: center;
  }

  &__summarySection {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__summarySectionRow {
    display: flex;
    flex-direction: row;
  }

  &__summarySectionItem {
    width: 220px;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    margin-bottom: 20px;
  }

  &__pdfNameSummary {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  &__summarySectionItemTitle {
    font-size: 12px;

  }

  &__summarySectionItemValue {
    font-size: 14px;
    font-weight: 700;
  }

  &__titleSection {
    margin-top: 60px;
    font-weight:700;
    font-size: 20px;
    @include theme-aware('color', 'color-main');
  }

}

