@import "../../../styles/Variables";
@import "../../../styles/Theme";

.addEsgCertificate {

  &__containerItem {
    display: flex;
  }

  &__item {
    margin-right: 10px;
  }

  &__titleRateData {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: -40px;
    @include theme-aware('color', "color-main");
    font-weight: 700;
    font-size: 24px;
    @include mobile {
      margin-left: 0;
    }
  }

}