/* COLOR VARIABLES */
$COLOR_DARK_GREY: #2B2B2B;
$COLOR_TEXT_BORDEAUX: rgba(#FF0000, 0.75);
$COLOR_GREY: #979797;
$COLOR_TEXT_GREY: #727272;
$COLOR_TEXT_DISABLED: #D2D2D2;
$COLOR_TEXT_GREEN: #009D22;
$COLOR_BACKGROUND: #F3F3F3;
$COLOR_BACKGROUND_WHITE: #FFFFFF;
$COLOR_BACKGROUND_GREY: #D8D8D8;
$COLOR_SUCCESS: #79C88A;

$LINEAR_GRADIENT_TRIPLE: linear-gradient(270deg, #F6973F 0%, #FC5F6E 40.59%, #596EF5 100%);
$COLOR_LINEAR_GRADIENT_GRAY: linear-gradient(322.85deg, #AEAEAE, #CDCDCD);

/*Color Palettes*/

/*PURPLE*/
$COLOR_PURPLE_LIGHT: #FE666C;
$COLOR_ERROR: $COLOR_PURPLE_LIGHT;
$COLOR_PURPLE: #F74378;
$COLOR_LINEAR_GRADIENT_PURPLE: linear-gradient(322.85deg, $COLOR_PURPLE_LIGHT, $COLOR_PURPLE);
$COLOR_PURPLE_HOVER: rgba($COLOR_PURPLE, 0.50);
$COLOR_PURPLE_LANDING_PAGE: url('../assets/Images/landing_page_purple.svg');

/*ORANGE*/
$COLOR_ORANGE_LIGHT: #FFBE55;
$COLOR_ORANGE: #F6973E;
$COLOR_LINEAR_GRADIENT_ORANGE: linear-gradient(322.85deg, $COLOR_ORANGE_LIGHT, $COLOR_ORANGE);
$COLOR_ORANGE_HOVER: rgba($COLOR_ORANGE, 0.50);
$COLOR_ORANGE_LANDING_PAGE: url('../assets/Images/landing_page_orange.svg');

/*BLUE*/
$COLOR_BLUE_LIGHT: #6175F8;
$COLOR_BLUE: #4259ED;
$COLOR_LINEAR_GRADIENT_BLUE: linear-gradient(322.85deg, $COLOR_BLUE_LIGHT, $COLOR_BLUE);
$COLOR_BLUE_HOVER: rgba($COLOR_BLUE, 0.50);
$COLOR_BLUE_LANDING_PAGE: url('../assets/Images/landing_page_blue.png');

/*DARK BLUE*/
$COLOR_DARK_BLUE_LIGHT: #1E3A6E;
$COLOR_DARK_BLUE: #315F87;
$COLOR_LINEAR_GRADIENT_DARK_BLUE: linear-gradient(322.85deg, $COLOR_DARK_BLUE_LIGHT, $COLOR_DARK_BLUE);;
$COLOR_DARK_BLUE_HOVER: rgba($COLOR_DARK_BLUE, 0.30);

$themes: (
        purple: (
                color-background:$COLOR_LINEAR_GRADIENT_PURPLE,
                color-main: $COLOR_PURPLE,
                color-main-light: $COLOR_PURPLE_LIGHT,
                color-main-light-hover: $COLOR_PURPLE_HOVER,
                onboarding-url:$COLOR_PURPLE_LANDING_PAGE,
                gray-bar:$COLOR_LINEAR_GRADIENT_GRAY,
        ),
        blue: (
                color-background: $COLOR_LINEAR_GRADIENT_BLUE,
                color-main: $COLOR_BLUE,
                color-main-light: $COLOR_BLUE_LIGHT,
                color-main-light-hover: $COLOR_BLUE_HOVER,
                onboarding-url:$COLOR_BLUE_LANDING_PAGE,
                gray-bar:$COLOR_LINEAR_GRADIENT_GRAY,
        ),
        orange: (
                color-background: $COLOR_LINEAR_GRADIENT_ORANGE,
                color-main: $COLOR_ORANGE,
                color-main-light: $COLOR_ORANGE_LIGHT,
                color-main-light-hover: $COLOR_ORANGE_HOVER,
                onboarding-url:$COLOR_ORANGE_LANDING_PAGE,
                gray-bar:$COLOR_LINEAR_GRADIENT_GRAY,
        ),
        darkblue: (
                color-background:$COLOR_LINEAR_GRADIENT_DARK_BLUE,
                color-main: $COLOR_DARK_BLUE,
                color-main-light: $COLOR_DARK_BLUE_LIGHT,
                color-main-light-hover: $COLOR_DARK_BLUE_HOVER,
        )
);
/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */
@mixin theme-aware($key, $color) {
  @each $theme-name, $theme-color in $themes {
    .theme--#{$theme-name} & {
      $found: map-get(map-get($themes, $theme-name), $color);
      #{$key}: if($found, $found, $color)
    }
  }
}
