@import "../../../styles/Theme";
@import "../../../styles/Variables.scss";

.screenWrapper {
  margin-top: 30px;
  margin-bottom: 40px;
  position: relative;

  &__title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 15px;
    color: $COLOR_DARK_GREY;
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    margin-bottom: 40px;

    &--formattedText {
      white-space: pre;
    }
  }

  &__summaryComponentSection {
    display: flex;
    flex: 1;
  }

  &__containerTopSection {
    display: flex;
    flex: 1;
    flex-direction: row;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__containerButton {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
