@import "../../../styles/Theme";

.processComplete {
  margin-top: 40px;

  &__content {
    padding: 40px 80px;
  }

  &__img {
    margin-bottom: 20px;
  }

  &__title {
    color: $COLOR_DARK_GREY;
    font-weight:700;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__buttonContainer {
    text-align: right;
  }

  &__description {
    color: $COLOR_DARK_GREY;
    font-weight:400;
    font-size: 16px;
    width: 600px;
    display: flex;
    justify-content: flex-start;
    padding-right: 40px;
    margin-right: auto;
  }

  &__button {
    display: inline-block;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 1rem;

    &--back {
      margin-right: 20px;
    }
  }
}
