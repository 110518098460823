.actions {
  &__tableRow {
    display: flex;
    flex-direction: row;
    margin: 10px 20px 10px;

    & + & {
      border-top: 1px solid rgba(151, 151, 151, 0.5);
    }
  }

  &__pdfContainer {
    text-align: center;
  }

  &__documentPDFContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  &__pdfImage {
    cursor: pointer;
    height: 30px;
    text-align: center;
    margin: 0 5px 5px 0;
  }

  &__signers {
    flex-direction: column;
    align-items: flex-start;
  }

  &__signerContainer + &__signerContainer {
    margin-top: 10px;
  }

  &__signedIcon {
    padding-right: 5px;
  }

  &__marginTop20 {
    margin-top: 20px;
  }
}
