@import "../../../styles/Theme";
@import "../../../styles/Variables";

.noDataBox {
  background-color: $COLOR_BACKGROUND;

  &__noCreditBox {
    padding: 40px 40px 40px 60px;
  }

  &__mainTitle {
    font-weight:700;
    font-size: 28px;
    margin-bottom: 20px;
  }

  &__noDataTitle {
    padding-bottom: 21px;
    font-weight:700;
    font-size: 60px;
  }

  &__text {
    font-weight:400;
    font-size: 16px;
    min-width: 90%;
    max-width: 90%;
    text-align: left;
    white-space: pre-line;
  }

  &__bottomSection {
    padding-top: 40px;
    padding-right: 40px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    font-weight:700;
    font-size: 16px;
    color: $COLOR_ERROR;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }

  &__error {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    @include tablet {
      margin-bottom: 20px;
      justify-content: flex-start;
    }
  }
}
