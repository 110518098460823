@import "../../../styles/Variables";
@import "../../../styles/Theme";

.uploadPDF {
  &__bottomSection {
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
  }

  &__contentBottomSection {
    display: flex;
    flex: 1;
    @include mobile {
      flex-direction: column;
    }
  }

  &__pdfImageContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    align-self: center;
    margin-left: -40px;
    cursor: pointer;
    width: 100%;
    margin-right: 20px;

    @include mobile {
      margin-left: 0;
    }
  }

  &__imgContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }

  &__pdfImage {
    cursor: pointer;
    height: 90px;
    width: 68.11px;
  }

  &__contentRight {
    display: flex;
    flex: 4 1;
    flex-direction: column;
  }

  &__error {
    display: flex;
    align-items: center;
    margin-left: -20px
  }

  &__containerButtons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 20px;
    padding-top: 40px;
    @include mobile {
      display: flex;
      flex-direction: column;
    }
  }

  &__confirmButton {
    @include mobile {
      margin-top: 20px;
      width: 100%;
    }
  }
}