@import "../../../styles/Theme";
@import "../../../styles/Variables";


.customInput {
  font-size: 16px;
  padding-left: 20px;
  outline: none;
  width: 100%;

  &__container {
    display: flex;
    width: 100%;
    height: 42px;
    flex: 1;
    border: 1px $COLOR_DARK_GREY solid;
    background-color: white;
    border-radius: 7px;

    &--noBorder {
      border: none;
    }

    &--outlineNone {
      border: 1px $COLOR_GREY solid;
      border-radius: 7px;
    }

    &--noBorderRight {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0;
    }

    &--formatError {
      border-color: $COLOR_ERROR;
    }
  }

  &__title {
    color: $COLOR_DARK_GREY;
    font-size: 16px;
    font-weight: 700;
    flex-shrink: 0;
    width: 100%;
  }

  &__titleContainer {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;
  }

  &__input {
    border: none;
    outline: none;
    padding-left: 20px;
    font-weight: 600;
    min-width: 0;
    width: 80%;
    display: flex;
    padding-right: 5px;
    flex: 1 1;
    justify-items: flex-start;
    height: 100%;
    @include ellipsis;
    font-size: 16px;
    background-color: transparent;

    &--disabled {
      color: transparent;
      text-shadow: 0 0 0 $COLOR_GREY;

    }
  }

  &__textArea {
    resize: none;
    border: none;
    outline: none;
    padding-left: 20px;
    padding-top: 10px;
    font-weight: 600;
    min-width: 0;
    font-size: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    padding-right: 5px;
    flex: 1 1;
    border-radius: 7px;
  }

  &__inputContainer {
    font-weight: 600;
    color: $COLOR_TEXT_GREY;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding-right: 15px;
    width: 100%;
  }

  &__modify {
    @include theme-aware('color', "color-main");
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 3px;
    margin-top: 8px;
    margin-right: 20px;
  }
}



