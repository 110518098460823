.combinations {
  &__buttonsContainer {
    justify-content: flex-end;
    display: flex;
    margin-top: 20px;
  }

  &__tableRow {
    display: flex;
    flex-direction: row;
    margin: 0 40px;
    padding-bottom: 17px;
    cursor: pointer;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  }

  &__header {
    padding: 20px 40px 20px;
  }

  &__backButton {
    margin-right: 20px;
  }

  &__successButtonContainer {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;
  }

  &__successButton {
    margin-left: 20px;
    margin-top: 30px;
  }

  &__successEmail {
    margin-top: 15px;
    font-weight: 700;
  }

  &__successDescription1 {

    margin-bottom: 25px;
  }

  &__successDescription2 {

    margin-top: 40px;
  }
}
