@import '../../../styles/Theme';

.onBoardingQuestionnaire {

  &__rowWithButton {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 50px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    padding-top: 5px;
  }

  &__label {
    font-size: 16px;
    font-weight:700;
    color: $COLOR_DARK_GREY;
    padding-top: 30px;
  }

  &__button {
    height: 40px;
    width: 70px;
  }

  &__containerCustomInput {
    margin-right: 40px;
    width: 300px;
    margin-top: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
