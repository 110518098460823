.progressBar{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;

  &__title {

    font-style: italic;
    font-size: 16px;
    color: white;
  }
}

.progress {
  width: 230px;
  height: 8px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.progress-bar{
  background-color: #FFFFFF !important;
  border-radius: 7px;
}
