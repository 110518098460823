@import "../../../styles/Variables";

.rowInput {
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex: 1;
  align-items: flex-end;
  position: relative;
  @include tablet {
    flex-direction: column;
    padding: 0;
    display: block;

    &:first-child {
      padding: 0;
    }
  }

}

