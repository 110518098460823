@import './Variables';
@import './Theme';

html {
  background-color: $COLOR_BACKGROUND;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: lato, sans-serif;
  font-weight: 500;
}

pre {
  font-family: lato, sans-serif;
  font-weight: 500;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

button:not(:disabled) {
  cursor: pointer;
  outline: none !important;
}

input {
  font-weight: 600;
  color: $COLOR_DARK_GREY;
}

input:focus {
  outline: none;
}

.pageContainer {
  display: flex;
  background-color: $COLOR_BACKGROUND;
  height: 100vh;
  flex-direction: column;

  &__content {
    max-width: $CONTAINER_MAX_WIDTH !important;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    z-index: 1;

    @include container-max {
      padding: 0 20px;
    }
    @include mobile {
      padding: 0 10px;
    }
  }
}

.shadowCard {
  border-radius: 7px;
  background-color: $COLOR_BACKGROUND_WHITE;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);

  &--marginBottom80 {
    margin-bottom: 80px;
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $COLOR_TEXT_GREY;
  font-size: 16px;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $COLOR_TEXT_GREY;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $COLOR_TEXT_GREY;
}

.hidden {
  visibility: hidden;
  content-visibility: hidden;
}

.hide {
  display: none;
}

.displayNone {
  display: none;
}

.wordwrap {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.bold {
  font-weight: 700;
}

.underlined {
  text-decoration: underline;
}

.outlineNone {
  border: 1px solid $COLOR_TEXT_GREY;
  color: transparent;
  text-shadow: 0 0 0 $COLOR_TEXT_GREY;

  &:focus {
    outline: none;
  }
}

.white {
  color: white
}

.description {
  font-size: 16px;
  color: $COLOR_DARK_GREY;
}

.Toastify__toast {
  color: linear-gradient(322.85deg, $COLOR_SUCCESS, #2b883f);
  border-radius: 7px !important;
  min-height: 70px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
}

.Toastify__toast-body {
  margin-left: 5px;
}

.Toastify__close-button {
  margin-right: 5px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px !important;
}

.inputContainer {
  flex: 1 1 0;
  margin-right: 40px;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  @include tablet {
    margin-right: 0;
    margin-top: 15px;
  }
}

.listItem {
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
  align-content: flex-start;
  @include tablet {
    justify-content: flex-start;
  }

  &--center {
    display: flex;
    align-items: center;
  }

  &--smaller {
    width: 80px;
  }
}

.listItemTitle {
  font-size: 14px;
  white-space: nowrap;
  color: #6C6C6C;
  margin-right: 5px;

  &--gray {
    color: #A5A5A5;
  }

  &--smaller {
    font-size: 12px;
  }
}

.listItemTitle {
  font-size: 14px;
  white-space: nowrap;
  color: #6C6C6C;
  margin-right: 5px;

  &--gray {
    color: #A5A5A5;
  }

  &--smaller {
    font-size: 12px;
  }
}

.listItemValue {
  color: $COLOR_DARK_GREY;
  font-size: 16px;
  font-weight: 700;
  @include ellipsis;

  &--colored {
    @include theme-aware('color', 'color-main');
    font-size: 24px;
  }

  &--highlighted {
    @include theme-aware('color', 'color-main');
  }

  &--smaller {
    font-size: 14px;
  }
}

.line {
  border: 1px solid $COLOR_GREY;
  opacity: 0.2;
  margin-top: 15px;
  height: 1px;

  &--withoutMargin {
    margin-top: 0;
  }
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  @include mobile {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    margin-left: 40px;
  }
}

.containerButtonConfirm {
  &--marginRight20 {
    margin-right: 20px;
    @include mobile {
      margin-top: 20px;
      width: 100%;
      margin-right: 0;
    }
  }

  &--marginRight80 {
    margin-right: 80px;
    @include mobile {
      width: 100% !important;
      margin-top: 20px;
      margin-right: 0 !important;
    }
  }
}

.version {
  opacity: 0.7;
  position: fixed;
  bottom: 15px;
  right: 35px;
  font-size: 14px;
}

.searchBar {
  border: none !important;
  height: 40px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.tableHeader {
  padding: 20px 40px 20px !important;
}

.tableHeaderItem {
  font-weight: 700;
  font-size: 12px;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
  @include ellipsis;
}

.commonTableItem {
  min-width: 100px;
  font-size: 14px;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  @include ellipsis;
  padding-right: 10px;
}

.commonTableRow {
  display: flex;
  padding: 10px 40px;
  align-items: center;

  &--gray {
    background-color: rgba(151, 151, 151, 0.2);
  }
}

.loader {
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;

  .internal-circle,
  .external-circle {
    @include theme-aware('stroke', 'color-main');
    stroke-dashoffset: 0;
    transform-origin: center;
  }

  .internal-circle {
    stroke-dasharray: 187;
    animation: internal 1.8s ease-in-out infinite;
    opacity: .4;
  }

  .external-circle {
    stroke-dasharray: 312;
    animation: external 1.8s linear infinite;
    opacity: .9;
  }
}

@keyframes internal {
  0% {
    stroke-dashoffset: 187;
  }
  25% {
    stroke-dashoffset: 80;
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(360deg);
  }
}

@keyframes external {
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
}

.SingleDatePickerInput_clearDate__default:hover {
  background: none;
}

.Toastify__toast--info {
  background-color: $COLOR_TEXT_GREY !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.react-pdf__Page {
  background-color: white !important;
}
