@import "../../../styles/Theme";
@import "../../../styles/Variables";

.cardList {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__topSection {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__newItemButton {
    display: flex;
    justify-content: flex-end;
    margin-top: -10px;
    margin-bottom: 10px;
  }

  &__mainTitle {
    font-weight:700;
    font-size: 28px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__createProgramButton {
    justify-content: flex-start;
    padding-top: 5px;
    @include tablet {
      justify-content: flex-start;
    }
  }

  &__orderBySection {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -20px;
    @include tablet {
      margin-top: 20px;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__triangle {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    display: inline-flex;
    margin-left: 5px;
    margin-bottom: 1px;

    &--downTriangle {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }

  &__noItemsToRenderContainer {
   // margin-top: 20px
  }

  &__searchBarContainer {
    width: 400px;
    @include tablet {
      width: 100%;
    }
  }

  &__newItemButtonContainer {
    display: flex;
    justify-content: flex-end;
  }
}

.buttonStyle {
  margin-left: 20px;
}

