@import "../../../styles/Theme";

.customCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__containerCheck {
    display: flex;
    align-items: center;
  }

  &__body {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: #A5A5A5;
    border: 1px solid $COLOR_DARK_GREY;
    display: flex;
    justify-content: center;
    opacity: 0.3;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &--checked {
      opacity: 1;
      @include theme-aware('background-color', 'color-main');
      @include theme-aware('border-color', 'color-main');
    }

    &--radio {
      border-radius: 10px;
    }

    &--rounded {
      height: 26px;
      width: 26px;
      border-radius: 50px;
      border: 1px solid #BDBDBD !important;
      opacity: 1;
      background-color: transparent !important;
    }

    &--roundedChecked {
      height: 26px;
      width: 26px;
      border: 0 !important;
    }

    &--inverted {
      height: 18px;
      width: 18px;
      border-radius: 50px;
      border: 1px solid #BDBDBD !important;
      opacity: 1;
      background-color: #FFFFFF !important;
    }
  }

  &__description {
    cursor: pointer;
    align-items: flex-start;
    margin-left: 15px;
    font-size: 16px;

    color: $COLOR_DARK_GREY;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

}
