@import "../../../styles/Theme";
@import "../../../styles/Variables";

.sortingBar {
  &__orderByTitle {
    padding-bottom: 10px;
    font-weight:700;
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    height: 100%;

    @include tablet {
      display: flex;
      align-items: center;
      padding-bottom: 0;
      margin-right: 20px;
    }
  }

  &__orderByButtonsContainer {
    display: flex;
    flex-direction: row;
    margin-right: -10px;
    flex-wrap: wrap;
  }

  &__filterButton {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    padding-top: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__orderByButton {
    margin-right: 10px;
  }



  &__triangle {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
    display: inline-flex;
    margin-left: 5px;
    margin-bottom: 1px;

    &--downTriangle {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }
}
