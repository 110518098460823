@import "../../../styles/Theme.scss";

.downloadPDF{
  &__downloadContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &__downloadTitle {
    font-size: 16px;
    font-weight:700;
    margin-left: 15px;
    color: $COLOR_DARK_GREY;
    &:hover{
      text-decoration: underline;
    }
  }
}
