@import "../../../styles/Variables";
@import "../../../styles/Theme";

.CalendarDay__selected_span {
  background: #82e0aa; //background
  color: white; //text
  //border: 1px solid purple; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  @include theme-aware('background', 'color-background');
  color: white;
}

// Will edit when hovered over. _span buttonType also has this property
.CalendarDay__selected:hover {
  @include theme-aware('background', 'color-background');
  color: white;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  border: none;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  border-radius: 7px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  border-radius: 7px;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}

.SingleDatePickerInput__withBorder {
  border: 1px $COLOR_DARK_GREY solid;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  flex: 1;
  width: 100%;
  height: 42px;
}

.SingleDatePicker {
  width: 100%;

  &_picker {
    z-index: 2;
  }
}

.DateInput {
  flex: 1;
  width: auto;

  &:hover {
    cursor: pointer;
  }
}

.DateInput_input {
  padding-left: 20px;
  color: $COLOR_DARK_GREY;
  font-weight:600;
  font-size: 16px;
  height: 42px;
  &:hover{
    cursor: pointer;
  }
}

.DateInput_input__focused {
  border-color: white;
}

.SingleDatePickerLabel{
  display: flex;
  justify-content: center;

  &__text{

    font-size: 16px;
  }
}

select {
  background-color: transparent;
  border: 0;
  padding-right: 5px;
  padding-left: 5px;
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;

  &:hover{
    text-decoration: underline;
  }

  &:active,
  &:checked,
  &:focus{
    text-decoration: none;
  }

}

.datePicker {
  &--wrong {
    color: $COLOR_ORANGE !important;
  }
}