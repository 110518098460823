.documentSection {
  &__titleContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  &__buttonContainer {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }

  &__dropdownContainer {
    width: 70%;
  }

  &__tableItem{
    font-size: 12px;
    flex: none;
    margin: 0 1rem 0;

    &:last-child {
      justify-content: flex-start;
    }
  }
}
