@import '../../../styles/Variables';

.customProgressNavBar {

  &__navBar {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-top: 15px;
    margin-left: 40px;
  }

  &__labelBox {
    display: inline-flex;
    @include tablet{
      display: none;
    }
  }

  &__tickImage {
    width: 16px;
    height: 16px;
    @include tablet {
      display: none;
    }
  }

  &__menuItem {
    flex: 1;
    font-weight:700;
    font-size: 14px;
    line-height: 17px;
    color: white;
    margin-right: 60px;
    opacity: 0.6;
    text-transform: uppercase;
    @include tablet {
      display: none;
    }

    &--completed {
      padding-left: 10px;
      opacity: 1;
    }

    &--active {
      opacity: 1;
      @include tablet {
        display: flex;
      }
    }
  }

}
