@import "../../../styles/Theme";
@import "../../../styles/Variables";


.userCompanySummary {

  &__filesContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: flex-end;
    @include tablet {
      flex-direction: column;
    }
  }

  &__containerDownloadPDF {
    margin-left: 30px;
    flex: 1;
  }

  &__downloadPDF {
    width: 250px;
    display: inline-block;
    padding-right: 30px;
  }
  &__error {
    flex: 1;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 80px;
    color: $COLOR_ERROR;
  }
}
