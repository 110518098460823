@import "../../../styles/Theme";
@import "../../../styles/Variables";

.newUserCard {
  width: 100%;
  display: flex;
  flex: 1;
  position: relative;
  height: 100%;
  justify-content: center;
  &--clickable {
    cursor: pointer;
  }

  &__card {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 24px;
    width: 100%;
    margin-bottom: 20px;

    &--marginRight {
      margin-right: 30px;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }

  &__titleContainer {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
  }
  &__company {
    margin-top: 15px;
    font-weight: 700;
    font-size: 14px;
    color: $COLOR_TEXT_GREY;
    max-width: 140px;
    text-align: center;
    word-break: break-word;
    @include tablet {
      max-width: 100%;
    }
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    color: $COLOR_DARK_GREY;
    white-space: nowrap;
    max-width: 140px;
    @include ellipsis;
    @include tablet {
      max-width: 100%;
    }

    &--marginBottom5px {
      margin-bottom: 5px;
    }

    &--marginBottom20px {
      margin-bottom: 20px;
    }
  }

  &__description {
    opacity: 0.5;
    color: $COLOR_DARK_GREY;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: -5px;
  }

  &__image {
    margin-bottom: 24px;
  }

  &__selected {
    position: absolute;
    top: 15px;
    right: 19px;
  }

  &--gray {
    fill: #929292;
    color: #929292;
  }

  &--colored {
    @include theme-aware("fill", "color-main");
  }

  &__counter {
    height: 22px;
    width: 22px;
    line-height: 22px;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    color: white;
    @include theme-aware("background", "color-background");

    &--inverted {
      background: $COLOR_BACKGROUND !important;
      @include theme-aware("color", "color-main");
    }
  }
}
