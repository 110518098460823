@import '../../styles/Variables';
@import "../../styles/Theme";

.moneylenderVendorInfo {

  &__input {
    color: white;
    border: 1px white solid;
  }

  &__titleStyle {
    color: white;
  }

  &__dropdown {
    border: 1px white solid;
    color: white;
    background: transparent;
  }

  &__line {
    height: 10px;
    @include theme-aware('background-color', 'color-main');
    width: auto;
    margin: 40px -80px 20px;
    @include mobile {
      margin-left: -40px;
      margin-right: -40px;
    }
  }

  &__subTitle {
    font-size: 22px;
    font-weight: 700;
    @include theme-aware("color", "color-main");
    margin-bottom: 20px;
    margin-top: 30px;
  }

  &__filesContainer {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-bottom: 40px;
    justify-content: space-between;
    @include tablet {
      flex-direction: column;
    }
  }

  &__containerDownloadPDF {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    flex: 1;
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    margin-right: 10px;
    @include theme-aware('color', "color-main");

    @include mobile {
      font-size: 18px;
    }
  }

  &__secondChild {
    font-weight: 700;
    font-size: 14px;
    color: $COLOR_DARK_GREY;
    opacity: 0.6;
    margin-right: 40px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__firstChild {
    font-size: 14px;
    color: $COLOR_DARK_GREY;
    opacity: 0.6;
    margin-right: 5px;
  }

  &__rightSide {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
    @include tablet {
      margin-top: 10px;
    }
    @include mobile {
      margin-top: 20px;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__rightSideSub {
    display: flex;
    flex-direction: column;
    &+&{
      margin-left: 10px;
    }
  }


  &__topSection {
    margin-top: 20px;
    display: flex;
    flex: 1;
    align-items: center;
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__contentData {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 40px;
    @include mobile {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &__containerButtons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    padding-bottom: 40px;
  }

  &__containerButtons {
    &--marginRight20 {
      margin-right: 20px;
    }

    &--marginRight80 {
      margin-right: 80px;
    }
  }

  &__inputContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
  }

  &__button {
    width: 100%;
  }

}
