@import '../../../styles/Variables';
@import "../../../styles/Theme";

.cardContainerWithButtons {

  &__content {
    flex: 1 1;
    display: flex;
    margin-top: -20px;
    margin-bottom: 100px;
    padding-bottom: 40px;
    flex-direction: column;
    overflow: auto;
  }

  &__title {
    font-weight:700;
    font-size: 24px;
    padding-top: 20px;
    margin-left: 40px;
    margin-bottom: 20px;
    @include theme-aware('color', "color-main");
    line-height: 34px;

  }

  &__subtitle {
    color: $COLOR_DARK_GREY;
    font-weight:700;
    font-size: 20px;
    margin-left: 80px;
    margin-top: 10px;
    margin-bottom: 20px;
    @include  mobile{
      margin-left: 40px;
    }
  }

  &__description {

    font-size: 16px;
    color: $COLOR_DARK_GREY;
    margin: 0 80px 20px;
    @include  mobile{
      margin: 0 40px 20px;
    }
  }

  &__contentData {
    display: flex;
    flex-direction: column;
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 40px;

    @include mobile {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &__error {
    flex: 1;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    margin-left: 80px;
    color: $COLOR_ERROR;
  }

}
