@import '../../../styles/Variables';
@import '../../../styles/Theme';

.customCard {
  margin-top: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 100px;
  }

  &__mainContainer {
    width: 100%;
    padding: 20px 40px 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__containerData {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
  }

  &__containerTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include mobile {
      flex-direction: column;
    }
  }

  &__title {
    font-weight: 700;
    @include theme-aware('color', 'color-main');
    font-size: 24px;

    &--title20px {
      font-size: 20px;
    }
  }

  &__rightSubTitle {
    margin-left: auto;
    display: flex;
    align-items: center;
    @include mobile {
      margin-left: 0;
      display: flex;
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  &__rightSubTitleCont {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + & {
      margin-left: 15px;
    }
  }

  &__warning {
    text-align: center;
    @include theme-aware('color', $COLOR_ORANGE);
    font-size: 15px;
    font-weight: 700;
  }

  &__rightBodyCont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__rightSubTitleTitle {
    color: $COLOR_TEXT_GREY;
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }


  &__rightBodyTop {
    font-weight: 700;
    color: #7a7a7a;
    font-size: 14px;
  }

  &__rightBodyBottom {
    font-weight: 700;
    margin-top: 5px;
    font-size: 14px;
  }

  &__rightSubTitleValue {
    font-weight: 700;
    @include theme-aware('color', 'color-main');
    font-size: 25px;
    @include mobile {
      font-size: 20px;
    }
  }

  &__rightValue {
    font-weight: 700;
    color: $COLOR_DARK_GREY;
  }


  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__leftSide {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    flex: 4;
  }

  &__secondChild {
    font-weight: 700;
    font-size: 14px;
    color: $COLOR_DARK_GREY;
    opacity: 0.6;
    margin-right: 40px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__firstChild {
    font-size: 14px;
    color: $COLOR_DARK_GREY;
    opacity: 0.6;
    margin-right: 5px;
  }

  &__rightSideContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  &__rightSide {
    display: flex;
    flex-direction: column;
  }

  &__clickableText {
    @include theme-aware('color', 'color-main');
    font-weight: 700;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;

    &--blocked {
      cursor: not-allowed;
    }
  }

  &__item {
    width: 210px;
    margin-top: 30px;
    @include tablet {
      justify-content: flex-start;
    }
    @include mobile {
      width: 100%;
    }

    &--flex {
      width: auto;
      margin-right: 30px;
    }
  }

  &__containerShowInfoButton {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-right: 60px;
    margin-bottom: 40px;
    margin-top: -60px;
  }

  &__description {
    flex: 4;
    margin-top: 30px;
    @include tablet {
      justify-content: flex-start;
      flex-direction: column;
      flex: 1;
    }
  }

  &__moreInfoButton {
    flex: 1;
    align-self: flex-end;

    @include tablet {
      flex-direction: column;
    }
  }

  &__clickableTextShowInfo {
    display: flex;
    width: 100%;
    @include theme-aware('color', 'color-main');
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 5px;
    padding-bottom: 10px;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
  }

  &__clickableText {
    width: 100%;
    @include theme-aware('color', 'color-main');
    cursor: pointer;
    margin-top: 5px;
    margin-left: 5px;
    padding-bottom: 10px;
    text-decoration: underline;
    justify-content: center;
    align-items: center;
  }

}
