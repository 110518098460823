@import "../../../../styles/Theme";
@import "../../../../styles/Variables";

.OnBoardingEffectiveOwner {

  &__cardContainer {
    display: inline-flex;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 40px;

    @include tablet {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__cardItem {
    @include tablet {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  &__styleCard {
    box-sizing: border-box;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    background-color: #FAFAFA;
    margin-top: 30px;
    width: 200px;

    @include tablet {
      width: 100%;
    }
  }

  &__icon {
    display: flex;
    @include theme-aware('fill', 'color-main');
  }

  &__colorStroke {
    @include theme-aware('stroke', 'color-main');

    &--white {
      @include theme-aware('stroke', white);
    }
  }

  &__colorFill {
    @include theme-aware('fill', 'color-main');

    &--white {
      @include theme-aware('fill', white);
    }
  }

  &__buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    @include tablet {
      margin-bottom: 40px;
      width: 100%;
    }
  }
}
