@import "../../styles/Variables";
@import "../../styles/Theme";

.createDynamicDiscount {
  padding: 30px 0;
  background-color: $COLOR_BACKGROUND;

  &__createBox {
    min-width: 100%;
    padding-top: 20px;
  }

  &__title {
    font-weight: 700;
    @include theme-aware('color', 'color-main');
    font-size: 28px;
    color: $COLOR_DARK_GREY;
  }

  &__inputsBox {
    padding: 0 40px;
    @include tablet {
      padding-left: 20px;
    }
  }

  &__item {
    overflow: auto;
    width: 30%;
  }

  &__tableRow {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    align-items: center;
  }

  &__finalCell {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    @include tablet {
      display: flex;
      justify-content: flex-end;
      padding-top: 40px;
    }
  }

  &__createButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__error {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    font-weight: 700;
    align-items: center;
    color: $COLOR_ERROR;
    font-size: 16px;
    padding-bottom: 15px;

    &--errorDisabled {
      visibility: hidden;
    }
  }

  &__addButton {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__descriptionInput {
    max-width: 891px;
    color: $COLOR_DARK_GREY;

    font-size: 14px;
    margin-bottom: 10px;
  }

  &__currency {
    font-weight: 700;
    font-size: 18px;
  }

  &__customTableStyle {
    flex: 3;
    margin-right: 10px;
  }

  &__noPrograms {
    font-weight: 700;
    font-size: 16px;
    margin-top: 10px;
    color: $COLOR_ERROR;
  }

  &__tableHeader {
    padding: 20px 0 0 !important;
  }
}

