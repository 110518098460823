@import "../../../styles/Variables";
@import "../../../styles/Theme";

.table {
  margin-top: 10px;
  min-width: 900px;


  &__tableWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
  }

  &__topSection {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  &__searchBarContainer {
    margin-top: 20px;
    width: 400px;
    @include tablet {
      width: 100%;
    }
  }

  &__scrollContainer {
    overflow-x: auto;
  }

  &__header {
    display: flex;
    flex-direction: row;
    padding: 30px 40px 0;
    font-size: 12px;
    flex: 1;

    &--noPadding {
      padding: 0;
    }

    &--actions {
      padding: 20px 20px;
    }

    &--moreSpace {
      flex: 2;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__headerItem {
    font-weight: 700;
    @include theme-aware('color', "color-main");
    font-size: 12px;
    flex: 1;
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    align-items: center;
    padding-right: 10px;

    &--noSorting {
      cursor: no-drop;
    }

    &--actions {
      flex: none;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;

      &:last-child.table__headerItem {
        display: inline-block;
        flex: none;
        text-align: start;
        padding-right: 0;
      }
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: 0;

      &--moreSpace {
        flex: 2;
      }
    }

    &--white {
      color: white !important;
    }

    &--black {
      color: $COLOR_DARK_GREY !important;
    }
  }

  &__item {
    @include ellipsis;
    font-size: 14px;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    min-width: 0;
    align-items: center;
    padding-right: 10px;

    &--moreSpace {
      flex: 2;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: 0;
    }

    &__filtersTitle {
      font-weight: 700;
      font-size: 16px;
      margin-top: 30px;
      padding-bottom: 5px;
    }

    &--smallerFont {
      font-size: 12px;
    }

    &--actions {
      font-size: 12px;
      flex: none;
      align-items: start;
      margin: 16px 1rem 0;

      &:last-child {
        justify-content: flex-start;
      }
    }

    &--requests {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }

  &__line {
    border-top: 1px solid $COLOR_GREY;

    &--last {
      margin-bottom: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &--withMargin {
      margin-left: 40px;
      margin-right: 40px;
    }
  }

  &__row {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
  }

  &__errorOnTheLeft {
    font-size: 16px;
    font-weight: 700;

    &--colored {
      @include theme-aware('color', "color-main");
    }
  }

  &__select {
    text-transform: none;
    outline: none;
    cursor: pointer;
    font-family: lato, sans-serif;

    &:hover {
      text-transform: none;
      outline: none;
    }
  }

  &__containerArrow {
    display: flex;
    justify-content: center;
  }

  &__orderBySection {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -20px;
    @include tablet {
      margin-top: 20px;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__triangle {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #727272;
    display: inline-flex;
    margin-left: 5px;
    margin-bottom: 1px;
    align-self: center;

    &--downTriangle {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }

    &--left {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      cursor: pointer;
    }

    &--right {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      cursor: pointer;
    }
  }

  &__content {
    display: inline-block;
    padding: 20px 40px;
    width: 100%;
  }

  &__rightSide {
    float: right;
  }

  &__arrow {
    border-width: 0 2px 2px 0;
    display: inline-block;
    height: 9px;
    width: 9px;
    cursor: pointer;

    &--left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      margin-right: 32px;
    }

    &--right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      background-color: black;
    }
  }

  &__numberOfPage {
    @include theme-aware('color', "color-main");
    font-size: 14px;
    font-weight: 700;
    padding-right: 22px;
  }

  &__error {
    color: $COLOR_ERROR;
    font-size: 14px;
    font-weight: 400;
  }

  &__filterSection {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -20px;
    @include tablet {
      margin-top: 20px;
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__errorMessageContainer {
    margin-top: 20px;
  }

}
